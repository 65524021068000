body,
#root {
  margin: 0;
  padding: 0;
}

.App {
  text-align: center;
  /* Adjusted margin-top from 100px to 20px */
  font-family: Arial, sans-serif;
}

.searchContainer {
  margin: 20px auto;
  display: block;
  max-width: 600px;
}

.buttons {
  display: flex;
  justify-content: center;
  gap: 20px;
}

button {
  padding: 10px 20px;
  font-size: 14px;
  background-color: #f8f8f8;
  color: #5F6368;
  border: 1px solid #f8f8f8;
  border-radius: 4px;
  cursor: pointer;
  outline: none;
}

button:hover {
  background-color: #f1f1f1;
}

.radioGroup {
  display: flex;
  justify-content: center;
  margin-bottom: 0px;
}

.radioOption {
  margin: 0 10px;
  display: flex;
  align-items: center;
}

.radioOption input {
  margin-right: 8px;
}

.radioOption label {
  margin: 0;
  padding: 0;
  font-size: 16px;
}

.questionsGroup {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.questionOption {
  margin-bottom: 8px;
}

.questionOption label {
  font-size: 1rem;
  display: flex;
  align-items: center;
  /* Align to the start to handle multiline texts gracefully */
}

.questionOption input {
  margin-right: 8px;
}

.app-container {
  display: flex;
  width: 100vw;
  height: 100vh;
  overflow-y: auto;
  background-color: #F5F5F5;
}

.left-panel {
  transition: all 0.3s ease;
  width: 20%;
  height: 100vh;
  border-right: 1px solid #ddd;
}

.main-content {
  flex-grow: 1;
  overflow-y: hidden;
  max-height: calc(100vh - 40px);
  /* Reduce the max height by the footer's height */
}

/* Add this to your CSS file */
.list-scroll-container {
  max-height: calc(100vh - 60px);
  overflow-y: auto;
  scrollbar-color: rgba(160, 160, 160, 0.6) rgba(240, 240, 240, 0.6);
}

/* For Webkit browsers like Chrome and Safari */
.list-scroll-container::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
}

.list-scroll-container::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, .5);
  -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, .5);
  background-color: rgba(160, 160, 160, 0.6);
  /* Semi-transparent grey */
  border-radius: 4px;
}

.list-scroll-container::-webkit-scrollbar-track {
  background-color: rgba(240, 240, 240, 0.6);
  /* Semi-transparent light grey */
}

.footer-message {
  position: fixed;
  bottom: 0;
  width: 100%;
  text-align: center;
  padding: 10px 0;
  /* Add any additional styling as needed */
}

/* Logo Styles */
.logo {
  margin-bottom: 10px;
  border-radius: 60%;
  width: 150px;
  height: 150px;
}

.logo-search-box {
  position: relative;
  width: 100%;
  max-width: 584px;
  text-align: center;
}

/* Search Box Styles */
.search-box {
  width: 100%;
}

/* Button Styles */
.quiz-button {
  margin-bottom: 20px;
  background-color: #1976D2;

  &:hover {
    background-color: #125a9b;
  }
}

.centered-flex-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #F5F5F5;
}

/* App.css */
.select-controls {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 10px;
}

/* Add this to your CSS file */
.list-scroll-container {
  max-height: calc(100vh - 60px);
  overflow-y: auto;
  scrollbar-color: rgba(160, 160, 160, 0.6) rgba(240, 240, 240, 0.6);
}

/* For Webkit browsers like Chrome and Safari */
.list-scroll-container::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
}

.list-scroll-container::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, .5);
  -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, .5);
  background-color: rgba(160, 160, 160, 0.6);
  /* Semi-transparent grey */
  border-radius: 4px;
}

.list-scroll-container::-webkit-scrollbar-track {
  background-color: rgba(240, 240, 240, 0.6);
  /* Semi-transparent light grey */
}


.questions-display {
  width: 100%;
  max-width: 784px;
  text-align: left;
  white-space: pre-wrap;
  margin: 20px 0;
  max-height: 50vh;
  overflow-y: auto;
  /* Ensure there's no conflicting styling here */
}
/* For Webkit browsers like Chrome and Safari */
.questions-display::-webkit-scrollbar {
  width: 7px;
}

.questions-display::-webkit-scrollbar-thumb {
  background-color: rgba(160, 160, 160, 0.6); /* Adjust transparency with the alpha value */
  border-radius: 4px;
}

.questions-display::-webkit-scrollbar-track {
  background-color: rgba(240, 240, 240, 0.6);
}

/* For Firefox */
.questions-display {
  scrollbar-color: rgba(160, 160, 160, 0.6) rgba(240, 240, 240, 0.6);
  scrollbar-width: thin; /* You can use 'auto' or 'thin' */
}


.toggle-list-arrow {
  top: 10px; /* Adjust as needed */
  left: 10px; /* Adjust as needed */
  /* Additional styling */
}

.no-questions-message {
  text-align: center;
  color: grey;
  font-style: italic;
  /* Add any other necessary styles */
}

.centered-textfield input {
  text-align: center;
}

@media only screen and (max-width: 768px) {
  .searchContainer {
    margin: 10px auto;
    max-width: 100%;
    padding: 0 10px;
  }

  .quizPageContainer {
    /* CSS styles here */
    padding: 20px;
    background-color: #f0f0f0; /* Example style */
}
  .buttons {
    flex-direction: column; /* Stack buttons vertically */
    gap: 10px;
  }

  button, .quiz-button {
    padding: 12px;
    font-size: 16px;
  }

  .radioGroup, .select-controls {
    flex-direction: column; /* Stack radio buttons or selections vertically */
    align-items: center;
  }

  .radioOption, .questionOption {
    margin: 5px 0; /* Reduce margin for compact layout */
  }

  .app-container {
    flex-direction: column; /* Stack left panel and main content vertically */
  }

  .left-panel {
    position: absolute; /* Position it absolutely within app-container */
    width: 100%; /* Adjust width as needed */
    height: 100%; /* Full height */
    top: 0;
    left: 0;
    background-color: white; /* Or any desired background color */
    z-index: 10; /* Ensure it's above the main content */
    display: none; /* Hide by default */
    overflow-y: auto; /* Add scroll for overflow content */
  }

  /* Show the panel */
  .left-panel.is-visible {
    display: block;
  }

  .main-content {
    overflow-y: auto;
    max-height: unset; /* Remove max height restriction */
  }

  .toggle-list-arrow {
    position: fixed; /* Example positioning */
    z-index: 20; /* Above the panel */
    display: block; /* Show toggle button */
    position: absolute; /* Absolute position within app container */
    top: 5px; /* Adjust as per layout */
    left: 5px; /* Adjust as per layout */
    z-index: 101; /* Above other elements */
  }

  .logo {
    width: 100px; /* Adjust logo size */
    height: 100px;
  }

  .questions-display, .list-scroll-container {
    max-height: 50vh; /* Adjust height */
    overflow-y: scroll; /* Ensure scrollability */
  }

  /* Scrollbar adjustments for smaller screens */
  .list-scroll-container::-webkit-scrollbar,
  .questions-display::-webkit-scrollbar {
    width: 5px;
  }

  .list-scroll-container::-webkit-scrollbar-thumb,
  .questions-display::-webkit-scrollbar-thumb {
    background-color: rgba(160, 160, 160, 0.8);
  }

  .list-scroll-container::-webkit-scrollbar-track,
  .questions-display::-webkit-scrollbar-track {
    background-color: rgba(240, 240, 240, 0.8);
  }
}


@media only screen and (max-width: 430px) {
  .searchContainer {
    margin: 10px auto;
    max-width: 100%;
    padding: 0 10px;
  }

  .buttons {
    flex-direction: column; /* Stack buttons vertically */
    gap: 10px;
  }

  button, .quiz-button {
    padding: 8px;
    font-size: 8px;
  }

  .radioGroup, .select-controls {
    flex-direction: column; /* Stack radio buttons or selections vertically */
    align-items: center;
  }

  .radioOption, .questionOption {
    margin: 5px 0; /* Reduce margin for compact layout */
  }

  .app-container {
    flex-direction: column; /* Stack left panel and main content vertically */
  }

  .left-panel {
    position: absolute; /* Position it absolutely within app-container */
    width: 100%; /* Adjust width as needed */
    height: 100%; /* Full height */
    top: 0;
    left: 0;
    background-color: white; /* Or any desired background color */
    z-index: 10; /* Ensure it's above the main content */
    display: none; /* Hide by default */
    overflow-y: auto; /* Add scroll for overflow content */
  }

  /* Show the panel */
  .left-panel.is-visible {
    display: block;
  }
  
  .main-content {
    overflow-y: auto;
    max-height: unset; /* Remove max height restriction */
  }

  .toggle-list-arrow {
    position: fixed; /* Example positioning */
    z-index: 20; /* Above the panel */
    display: block; /* Show toggle button */
    position: absolute; /* Absolute position within app container */
    top: 5px; /* Adjust as per layout */
    left: 5px; /* Adjust as per layout */
    z-index: 101; /* Above other elements */
    opacity:0.8;
    background-color: rgba(255, 255, 255, 0.5); /* Optional: add a semi-transparent background */
  }
  .toggle-list-arrow:hover {
    opacity: 0.8; /* Slightly increase opacity on hover for better visibility */
    /* Adjust hover styles as needed */
  }
  .logo {
    width: 50px; /* Adjust logo size */
    height: 50px;
  }

  .questions-display {
    background-color:lightgray;
    max-height: 40vh; /* Adjust height */
    max-width: fit-content;
    overflow-y:scroll; /* Ensure scrollability */
  }

  .list-scroll-container {
    max-width: fit-content;
    max-height: fit-content;
    overflow-y:auto; /* Ensure scrollability */
  }
  /* Scrollbar adjustments for smaller screens */
  .list-scroll-container::-webkit-scrollbar,
  .questions-display::-webkit-scrollbar {
    width: 5px;
  }

  .list-scroll-container::-webkit-scrollbar-thumb,
  .questions-display::-webkit-scrollbar-thumb {
    background-color: rgba(160, 160, 160, 0.8);
  }

  .list-scroll-container::-webkit-scrollbar-track,
  .questions-display::-webkit-scrollbar-track {
    background-color: rgba(240, 240, 240, 0.8);
  }
}


/* Global Layout Settings */
.conversation-layout {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Full height of the viewport */
  padding: 4px;
  box-sizing: border-box;
  border: 1px solid #ddd;
  box-shadow: 0 2px 10px rgba(0,0,0,0.1);
  border-radius: 8px;
  background-color: #340be7; /* Light background for the layout */
}

/* Message Container Styles */
.message-container {
  display: flex;
  width: 80%; /* Adjust width as necessary */
  justify-content: flex-end; /* Align content to the bottom */
  margin-bottom: 20px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 8px;
  background-color: #fff;
  overflow: auto;
  border-color: #fff
}

/* Individual Message Box Styles */
.model-box {
  flex: 1; /* Each box takes equal space */
  margin: 10px;
  padding: 20px;
  border: 1px solid #ccc;
  height: 300px; /* Adjust height as necessary */
  overflow-y: auto;
  text-align: left;
  background-color: #1d0bdd; /* Light blue background for the boxes */
  border-radius: 8px;
}

/* Input Area Styles */
.input-area {
  width: 80%; /* Align the width with the message container */
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

/* Styling for Buttons and Inputs */
input, button {
  margin-top: 10px;
  padding: 10px;
  border-radius: 4px;
  border: 1px solid #ccc;
}

button {
  cursor: pointer;
  background-color: #58a6ff; /* Light blue shade for buttons */
  color: white;
}

/* Error Message Styles */
.error-message {
  color: red;
  margin-top: 10px;
}

.message-container div {
  margin-bottom: 10px; /* Add some margin between messages */
}


.question {
  color: maroon; /* Or any color you prefer for questions */
}

.analysis {
  color: green; /* Or any color you prefer for analysis */
}
